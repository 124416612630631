<template>
  <div class="scheduling">
    <div class="scheduling_con">
      <h5 class="s_con_h5">{{$t('common.message.xzpb')}}</h5>
      <div class="s_con_form">
        <div class="f_single">
          <div :class="language=== 'en_US' ? 'w15fr' : ''" class="f_left">{{$t('home.personal.scheduling.gzr')}}:</div>
          <div class="f_right">
            <el-date-picker
              style="width: 100%;"
              v-model="params.workDate"
              type="date"
              :placeholder="$t('casesDetail.casesChildren.lookWearingRecord.xzrq')">
            </el-date-picker>
            <!--<select v-model="params.dayOfWeek">-->
              <!--<option value="" disabled style="color: #999;">{{$t('common.message.qxz')}}</option>-->
              <!--<option-->
                <!--:key="item"-->
                <!--:value="index"-->
                <!--v-for="(item, index) in weekList"-->
              <!--&gt;{{item}}-->
              <!--</option>-->
            <!--</select>-->
          </div>
        </div>
        <div class="f_single">
          <div :class="language=== 'en_US' ? 'w15fr' : ''" class="f_left">{{$t('home.personal.scheduling.sd')}}:</div>
          <div class="f_right">
            <el-time-select
              style="width: 48%;"
              :placeholder="$t('home.personal.scheduling.qssj')"
              v-model="params.startTime"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '18:30'
              }">
            </el-time-select>
            <span class="time_sp">-</span>
            <el-time-select
              style="width: 48%;"
              :placeholder="$t('home.personal.scheduling.jssj')"
              v-model="params.endTime"
              :picker-options="{
                start: '08:30',
                step: '00:15',
                end: '18:30',
                minTime: params.startTime
              }">
            </el-time-select>
          </div>
        </div>
        <div class="f_single">
          <div :class="language=== 'en_US' ? 'w15fr' : ''"  class="f_left">{{$t('home.personal.scheduling.szjg')}}:</div>
          <div class="f_right">
            <select
              v-model="params.clinicId"
              :disabled="!!this.selectedKey"
              :style="{cursor: this.selectedKey ? 'not-allowed' : 'auto'}"
            >
              <option value="" disabled style="color: #999;">{{$t('common.message.qxz')}}</option>
              <option
                :key="item.clinicId"
                :value="item.clinicId"
                v-for="item in clinicList"
              >{{item.clinicInfo && `${item.clinicInfo.name} ${item.clinicInfo.enName ? `(${item.clinicInfo.enName})` : ''}`}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="foo_btn">
        <div class="main_big_btn mr20 main_theme_color_btn_white" @click="removeScheduling">{{$t('common.message.qx')}}</div>
        <div class="main_big_btn main_theme_color_btn" @click="subScheduling">{{$t('common.message.qd')}}</div>
      </div>
    </div>
    <full-loading v-show="isLoading"/>
  </div>
</template>

<script>
  import FullLoading from 'components/full-loading/full-loading';
  import {doctorScheduleDetail, createDoctorSchedule, modifyDoctorSchedule} from 'common/api/user';
  import {clinicDoctorList} from 'common/api/cases';
  import {getUserId, notifyMsg, formatDate} from 'common/js/util';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        params: {
          workDate: '',
          startTime: '',
          endTime: '',
          doctorId: getUserId(),
          clinicId: ''
        },
        clinicList: [],
        isLoading: false,
        weekList: [
          this.$t('home.personal.scheduling.zy'),
          this.$t('home.personal.scheduling.ze'),
          this.$t('home.personal.scheduling.zs'),
          this.$t('home.personal.scheduling.zsi'),
          this.$t('home.personal.scheduling.zw'),
          this.$t('home.personal.scheduling.zl'),
          this.$t('home.personal.scheduling.zr')
        ]
      }
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
    },
    props: {
      type: {
        type: String,
        default: '0'
      },
      selectedKey: {
        type: String,
        default: ''
      }
    },
    created() {
      clinicDoctorList({
        doctorId: getUserId(),
        status: '1'
      }).then(data => {
        this.clinicList = data;
      });
    },
    methods: {
      subScheduling() {
        const scheduleApi = this.selectedKey ? modifyDoctorSchedule : createDoctorSchedule;
        if(this.selectedKey) {
          this.params.id = this.selectedKey;
        }
        const {workDate, startTime, endTime, clinicId} = this.params;
        if(!workDate || !startTime || !endTime || !clinicId) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxwz'));
        }
        this.isLoading = true;
        this.params.workDate = formatDate(workDate, 'yyyy-MM-dd');
        scheduleApi(this.params).then(() => {
          this.params = {
            workDate: '',
            startTime: '',
            endTime: '',
            doctorId: getUserId(),
            clinicId: ''
          };
          this.isLoading = false;
          notifyMsg(this, 'success', this.$t('common.message.czcg'));
          this.$emit('subScheduling');
        }).catch(() => {
          this.isLoading = false;
        });
      },
      removeScheduling() {
        this.params = {
          workDate: '',
          startTime: '',
          endTime: '',
          doctorId: getUserId(),
          clinicId: ''
        };
        this.$emit('removeScheduling');
      },
      schedulingDetail() {
        this.isLoading = true;
        doctorScheduleDetail(this.selectedKey).then(data => {
          this.isLoading = false;
          const {workDate, clinicId, startTime, endTime} = data;
          this.params = {
            workDate,
            clinicId,
            startTime,
            endTime
          };
        }).catch(() => {
          this.isLoading = false;
        });
      },
    },
    watch: {
      type(val) {
        this.schedulingTit = val === '0' ? this.$t('common.message.xz') : this.$t('common.message.xg');
        if(val === '1') {
          this.schedulingDetail();
        }
      }
    },
    components: {
      FullLoading
    }
  }
</script>

<style scoped lang="scss">
  .w15fr{
    width: 1.2rem;
    text-align: right;
  }
  .scheduling {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
    color: $main_theme_color_333;
    font-size: 0.18rem;
    .scheduling_con {
      width: 7rem;
      position: absolute;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      border-radius: 0.1rem;
      padding: 0.4rem 0.77rem 0.56rem;
      .s_con_h5 {
        font-size: 0.2rem;
        text-align: center;
        margin-bottom: 0.34rem;
      }
      .s_con_form {
        margin-bottom: 0.26rem;
        .f_single {
          display: flex;
          align-items: center;
          margin-bottom: 0.2rem;
          .f_left {
            min-width: 0.64rem;
            margin-right: 0.3rem;
            font-size: 0.14rem;
            text-align: right;
          }
          .f_right {
            flex: 1;
            position: relative;
            display: flex;
            align-items: center;
            height: 0.42rem;
            select {
              width: 100%;
              height: 100%;
              background-color: #F4F4F4;
              padding: 0.09rem 0.19rem;
            }
            input {
              &::-webkit-input-placeholder{
                font-size: 0.12rem;
              }
              &::-moz-placeholder{
                font-size: 0.12rem;
              }
              &:-ms-input-placeholder{
                font-size: 0.12rem;
              }
            }
            .time_sp{
              color: #3E3E3E;
              font-size: 0.2rem;
              margin: 0 0.1rem;
            }
          }
        }
      }
      .foo_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
</style>
